.footer {
	padding-bottom: 30px;

	@include media-breakpoint-up(md) {
		padding-bottom: 60px;
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 16.53675vh;
	}
}
